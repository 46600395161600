import "../public/styles/globals.css";
import type { AppProps } from "next/app";
import { NextComponentType } from "next";
import { SessionProvider, useSession } from "next-auth/react";
import AccessDeniedComponent from "../components/AccessDenied";
import { Session } from "next-auth";
import dynamic from "next/dynamic";
import CheckConnection from "@/components/CheckConnection";
import LayoutComponent from "@/components/Layout";
import DefaultAlert from "@/components/default-alert/DefaultAlert";
import ObservabilityInit from "@/components/Observability";

type CustomAppProps = AppProps & {
  Component: NextComponentType & { auth?: boolean }; // add auth type
};

export default function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: CustomAppProps) {
  const ScriptsComponent = dynamic(() => import("../components/Scripts"));
  return (
    <SessionProvider session={session}>
      <ObservabilityInit />
      <CheckConnection />
      <DefaultAlert />

      {Component.auth ? (
        <Auth>
          <LayoutComponent>
            <Component {...pageProps} />
          </LayoutComponent>
        </Auth>
      ) : (
        <Component {...pageProps} />
      )}
      <ScriptsComponent />
    </SessionProvider>
  );
}

const Auth = ({ children }: { children: JSX.Element }) => {
  const {
    data,
    status,
  }: {
    data: Session | null | undefined;
    status: "loading" | "authenticated" | "unauthenticated";
  } = useSession({
    required: true,
  });

  if (status === "loading") {
    return <span className="loading" />;
  }

  if (status === "authenticated" && data?.token?.tokenData?.token) {
    return children;
  } else {
    return <AccessDeniedComponent />;
  }
};
